@import '@tradesolution/iceberg-styles/src/scss/variables';

.table {
    border: none;
}

.periodBox {
    background-color: $Sideground-PU200;
    border-radius: 8px;
    border: 1px solid $GY200;
    padding: 10px;
    margin-right: 1rem;
    font-weight: 600;
    color: $Muted-GY400;
}

.rightAlign {
    text-align: end;
    width: 160px;
}

.middleAlign {
    text-align: center;
    width: 160px;
}

.tsTable {

    vertical-align: middle;

    tbody tr {
        background-color: white;
        border-top: 1px solid $GY200;
        border-bottom: 1px solid $GY200;
        vertical-align: middle;
    }

    tbody td.fitContent {
        width: 1%;
        white-space: nowrap;
    }

    // workaround as tr border-radius does not work
    tbody td:first-child {
        border-radius: 8px 0 0 8px;
    }

    tbody td:last-child {
        border-radius: 0 8px 8px 0;
    }
}

.missingDataCard {
    text-align: center;
    margin: 2rem 0;
}