.container {
  padding: 20px;
  width: 100%;
}

.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.tabPane {
  padding: 1em 0em;
}