.container {
  max-width: 1200px;
  margin: auto;
}

.mainHeader {
  text-align: center;
  font-size: 34px;
  font-weight: bold;
  margin-top: 56px;
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: 100%;
  }
}

.tileRow {
  display: flex;
  justify-content: center;
}

.tileCol {
  padding-top: 56px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tileContent {
  width: 185px;
  padding: 0.5em;
  display: flex;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
  text-decoration: none;
}

.tileContent:hover {
  cursor: pointer;
  transform: translate(0, -5px);
}

.productIcon {
  width: 100%;
  height: auto;
  max-width: 90px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 14px;
}

.productName {
  margin-top: 0.5em;
  font-size: 18px;
  color: #001f3d;
  font-weight: 600;
  text-decoration: none;
  text-align: center;
}
