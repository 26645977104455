.tableBody {
  background-color: #fff;
  width: 100%;
  margin-bottom: 1%;
}

.container {
  overflow: auto;
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.containerCollapse {
  overflow: auto;
  padding: 20px;
  width: 85%;
  transition: all 0.5s ease-in-out;
}

.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

@media only screen and (max-width: 600px) {
  .container {
    max-width: 100%;
  }

}

@media screen and (max-width: 1800px) {
  .containerCollapse {
    width: 75%;
  }
}