.email {
    margin: 0;
}

.telefon {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    color: #68778D;
    margin: 0;
    margin-top: 2px;
}