@import '@tradesolution/iceberg-styles/src/scss/variables';

// navbar

.navbar {
    background-color: $B700;
    color: $B000;
    font-weight: 600;
}

.navbar-light .navbar-nav .nav-link {
    color: $B000 !important;
}

// temp
.dropdown-toggle::after {
    content: none;
    display: none !important;
}


select,
.form-select {
    border-radius: 24px;
}

/* FONT START */
.deactivated-currency {
    color: $GY400;
    text-decoration: line-through;
}

.error-text {
    color: $Error-R500 !important;
}

p {
    line-height: 1.46rem;
}

b,
strong {
    font-weight: 700;
}

a {
    color: $Primary-B500;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

/* FONT END */

/* ALERTS START */

.alert {
    border-radius: 0.666rem;
    padding: 1.066rem;
}

.alert-info {
    background-color: $Secondary-B100;
    border-color: $Secondary-B100;
}

.alert-success {
    background-color: #F0FAEF;
    border-color: #F0FAEF;
}

.alert-danger {
    background-color: #FEF1F1;
    border-color: #FEF1F1;
}


/* ALERTS END */


/* TABLES START */

.table {

    vertical-align: middle !important;

    // passer på at bredde av kolonne tilpasses innhold
    tbody td.fitContent {
        width: 1%;
        white-space: nowrap;
    }
}

.table thead th button {
    text-align: end;
}

/* TABLES END */

/* NAVBAR START */
/* .navbar {
    font-size: 14px;
    line-height: 20px;
    min-height: 50px;
    background-color: $B700;
    font-weight: 500;
    padding: 0;
}

.navbar .navbar-brand img {
    height: 50px;
    width: 189px;
}

.navbar .navbar-nav .nav-link {
    color: #F7FAFC;
    margin: 0;
    height: 50px;
    display: flex;
    align-items: center;
} */

/* @media screen and (max-width: 1800px) {
    #navbar-dropdown-item {
        font-size: x-small;
    }
}

.navbar .navbar-nav .nav-link:hover {
    color: #F7FAFC;
    background-color: $Base-B900;
}

.navbar .navbar-nav .nav-link:focus {
    color: #F7FAFC;
    background-color: $Base-B900;
}

.navbar .navbar-toggler {
    height: 50px;
    border: none;
    padding-top: 0;
    padding-bottom: 0;
}

.navbar-light .navbar-brand {
    display: flex;
    align-items: center;
    color: #F7FAFC;
    height: 50px;
    padding-bottom: 0;
    padding-top: 0;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(255,255,255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.navbar .navbar-toggler:focus {
    box-shadow: none;
}

.navbar-collapse .nav-link {
    padding-right: 15px;
    padding-left: 15px;
}

.dropdown-toggle:after {
    border: none;
}

.navbar .navbar-nav .show>.nav-link {
    color: #F7FAFC;
} */

/* NAVBAR END */

/* ACTION CONTEXT MENU START */

.action-context-menu {
    margin-bottom: 0;
}

.action-context-menu td {
    padding: 1rem;
}

/* ACTION CONTEXT MENU END */