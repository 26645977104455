.popover {
  height: fit-content;
  padding-bottom: 3em;
  min-width: 350px;
}

.btnContainer {
  position: absolute;
  margin: auto;
  right: 1em;
  bottom: 1em;
}

.formGroup {
  margin-bottom: 0.5em;
}

.kundenavnFormControl {
  border-right: hidden;
}

.spinnerContainer {
  background: white;
  border-left: hidden;
  border-radius: 0.25rem !important;
}

.invalid {
  border-color: #dc3545;
}

.invalidFeedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}