.kundeKort {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(211, 209, 209, 0.1);
  padding: 20px;
}

.createButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}

.filterContainer {
  display: flex;
  margin-left: auto;
}

@media screen and (max-width: 1000px) {
  .createButtons {
    flex-direction: column;
  }
}

.spinnerContainer {
  top: 0;
  height: calc(100vh - 370px);
  width: 100%;
}

.picker {
  margin-left: 8px;
}

.missingDataCard {
  text-align: center;
  margin-bottom: 1rem;

  .cardBody {
    padding: 0.25rem;
  }
}