.table {
  border-collapse: unset;
}

.withHelpText {
  margin-bottom: 2px;
}

.invalidFeedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.tdPopoverMenu {
  width: 35px;
}

.helpText {
  font-size: 0.8rem;
  margin-right: 5px;
  float: right;
  color: #68778D;
}