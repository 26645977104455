.spinnerContainer {
  border-left: hidden;
}

.invalid {
  border-color: #dc3545;
}

.invalidFeedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}
