
.invalidFeedback {
 width: 100%;
 margin-top: 0.75rem;
 margin-bottom: 0.75rem;
 font-size: 0.875em;
 color: #dc3545;
}

/*ny tabell endringer*/
.table {
 border-collapse: unset;
}

.tbody {
 background-color: #fff;
 width: 100%;
 margin-bottom: 1%;
}
