.matrixTable {
    font-size: small;

    button {
        padding: 0 !important;
    }

    tbody {
        border: 0 !important;

    }

    th,
    td {
        text-align: end;
        border-bottom-width: 0 !important;
    }

    td:first-child {
        width: 100px;
    }
}