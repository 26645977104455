.withHelpText {
  margin-bottom: 2px;
}

.tdPopoverMenu {
  width: 35px;
}

.helpText {
  font-size: 0.8rem;
  margin-right: 5px;
  float: right;
  color: #68778D;
}

.wrapper {
  margin-bottom: 1.5rem;
}