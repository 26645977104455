/*for selve mottaker tabell*/

.container {
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}


.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}


.thead {
  color: #68778D;
  font-weight: normal;
}

.tbody {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
}

.wrapper {
  margin-bottom: 1.5rem;
}

.vislogg {
  line-height: 1.5;
  font-weight: 1.5;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  border: 1px solid;
  margin-right: 20px;
  padding: 0.375rem 0.75rem;
  border-radius: 0.25rem;
}