.table {
  border-collapse: unset;
}

.tdPopoverMenu {
  width: 35px;
}

.tbody {
  background-color: #fff;
  width: 100%;
  margin-bottom: 1%;
}

.wrapper {
  margin-bottom: 1.5rem;
}