.opprettBtn {
  margin-right: 10px;
  margin-top: 5px;
}

.invalidFeedback {
  width: 100%;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.875em;
  color: #dc3545;
}


.alignTableHeader{
  margin-top: 0px;
  margin-bottom: 8px;
  margin-right: 0px;
  margin-left: 0px;
}

/*ny tabell endringer*/
.table {
  border-collapse: unset;
  margin-bottom: 2px;
}

.tbody {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
}

.withHelpText {
  margin-bottom: 2px;
}