@import '@tradesolution/iceberg-styles/dist/css/iceberg.min.css';
//@import '@tradesolution/iceberg-styles/src/scss/variables';
@import 'react-dropdown-tree-select/dist/styles.css';
@import './iceberg-styles-candidates.scss';

iframe {
    border: none !important;
}

button.powerbi-page-navigation-pages.btn.btn-default.active {
    background-color: rgb(0, 153, 220);
    color: #F7FAFC;
}

.table-dark>tbody>tr:nth-child(2n + 1)>td,
.table-striped>tbody>tr:nth-child(2n + 1)>th {
    background-color: #333;
    color: #F7FAFC;
}

.table-dark>tbody>tr:nth-child(2n + 0)>td,
.table-striped>tbody>tr:nth-child(2n + 0)>th {
    background-color: #666;
    color: #F7FAFC;
}

.blue-background {
    background-color: $B700;
}

.logo-container {
    width: 200px;
}

ul>.bootstrap-typeahead.clearfix.open {
    width: 400px;
    margin: 10px;
}

/* MOVE THIS TO THE HOME PAGE */

.startpage-main-header {
    font-size: 34px;
    font-weight: bold;
}

/* START react-dropdown-tree-select */
.react-dropdown-tree-select .dropdown {
    width: 100%;
}

.react-dropdown-tree-select .dropdown .dropdown-content {
    padding: 0;
    max-height: 800px;
}

.react-dropdown-tree-select .dropdown .infinite-scroll-component {
    max-height: 800px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger {
    width: 100%;
    padding: 0.1em;
    line-height: 1.5;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.react-dropdown-tree-select .node>label {
    padding-right: 2em;
}

.react-dropdown-tree-select .node.disabled>label {
    cursor: not-allowed !important;
}

.react-dropdown-tree-select .toggle {
    cursor: pointer !important;
}

.reactDropdownMultiPicker .dropdown-trigger {
    border-radius: 0.25rem;
    padding: 5px;
}

.reactDropdownMultiPicker .dropdown-trigger>span:after {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    padding: 1em;
}

.reactDropdownMultiPicker .toggle {
    font-size: 18px;
    font-weight: bold;
    color: #555;
    padding: 5px;
}

.reactDropdownMultiPicker .node-label {
    font-size: 14px;
    font-weight: bold;
    color: #555;
}

.reactDropdownMultiPicker .dropdown .dropdown-trigger.arrow {
    padding: 0.2em;
}

.offcanvas-backdrop.show {
    opacity: 0;
}

.rbt {
    display: flex;
    width: fill-available;
}

.rbt-aux {
    margin-left: -30px;
    margin-top: 7px;
    margin-right: 7px;
    z-index: 10;
}

.rbt-close {
    border: none;
}

.rbt-close span {
    font-size: medium;
}

.tjenesteGruppeCol {
    width: 300px;
}