.tag-item .search::placeholder {
    color: #001F3D;
    font-weight: bold;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.bottom:after {
    margin-left: -18px;
    margin-right: 3px;
}

.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow.top:after {
    margin-left: -18px;
    margin-right: 3px;
}

.reactDropdownMultiPicker .dropdown .dropdown-trigger.arrow {
    border-radius: 24px;
    text-decoration: none;
}

.tag-item .search {
    background-color: transparent;
    border-bottom: none !important;
}

.tag-item .tag {
    border-radius: 20px;
    padding: 2px;
}