.container {
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}

.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.tbody {
  background-color: #fff;
  width: 100%;
  margin-bottom: 1%;
}

.wrapper {
  margin-bottom: 1.5rem;
}