.summaryTable {
  margin-bottom: 0;
}

.tdTgNavn {
  float: left;
  margin-left: 40px;
  margin-bottom: 10px;
  width: 200px;
}

.tdButton {
  width: 40px;
  margin-bottom: 10px;
  margin-left: 40px;
}

.tdTotalContacts {
  text-align: right;
  white-space: nowrap;
}

.expandButton {
  border: none;
  background: transparent;
  padding: 0;
  float: right;
}

.totalContacts {
  margin-left: 20%;
  float: left;
}