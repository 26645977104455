.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.tableBody {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
}

.brukerNavn {
  margin: 0;
}

.tsKunde {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #68778D;
  margin: 0;
  margin-top: 2px;
}


.popover>div {
  padding-bottom: 0;
}