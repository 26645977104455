.OverviewContainer {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 1%;
  border: 1px solid #e6e6e6;
  padding: 1rem;
  align-items: center;
  justify-content: center;
}

.companyName {
  min-width: 275.5px;
  min-height: 37px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  color: #001F3D;
}

.activeCustomer {
  color: #001F3D;
}

.category {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
  color: #68778D;
  margin: 0;
}

.categoryContent {
  font-family: 'Open Sans';
  font-size: 15px;
  color: #001F3D;
}

.syncButton {
  background: none;
  border: none;
}

.copyToClipboardButton {
  margin-right: 10px;
  margin: 10px;
}

.updateBtn {
  max-width: 40px;
  padding-right: 10px;
  margin-right: 10px;
  min-height: 40px;
}

@media screen and (max-width: 1000px) {
  .OverviewContainer {
    flex-direction: column;
  }
}

.popover {
  max-width: 180px;
}

.popover>div {
  padding-top: 10px;
  padding-bottom: 0;
}