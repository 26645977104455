.tableBody {
  background-color: #fff;
  width: 100%;
  margin-bottom: 1%;
}

.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}