.OverviewContainer {
  display: flex;
  flex-direction: row;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 10px;
  padding-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin: 10px;
  border: 1px solid #e6e6e6;
}

.tjenesteName {
  min-width: 275.5px;
  min-height: 37px;

  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 37px;
  /* identical to box height, or 132% */

  display: flex;
  align-items: center;

  /* Text/Base-B900 */

  color: #001F3D;


  /* Inside auto layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin-left: 10px;
}

.category {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #68778D;
  flex: none;
  order: 2;
  flex-grow: 0;
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.categoryContent {
  font-family: 'Open Sans';
  font-size: 15px;
  color: #001F3D;
}