.container {
  padding: 20px;
  width: 100%;
  transition: all 0.5s ease-in-out;
}


.mainHeader {
  text-align: left;
  font-size: 20px;
  font-weight: bold;
  margin-top: 0.5em;
  margin-bottom: 2em;
}


.thead {
  color: #68778D;
  font-weight: normal;
}

.tbody {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
}
.withHelpText {
  margin-bottom: 2px;
}

.wrapper {
  margin-bottom: 1.5rem;
}

/*css for createfakturafritak*/
.opprettBtn {
  margin-right: 10px;
  margin: 10px;
}