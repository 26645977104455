.withHelpText {
  margin-bottom: 2px;
}

.thTjenestegrupper {
  float: right;
  margin-right: 70px;
}

.helpText {
  font-size: 0.8rem;
  margin-right: 5px;
  float: right;
  color: #68778D;
}

.wrapper {
  margin-bottom: 1.5rem;
}

.kontaktpersonNavn {
  margin: 0;
}

.kontaktpersonTittel {
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  color: #68778D;
  margin: 0;
  margin-top: 2px;
}