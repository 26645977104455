.container {
  height: 94vh;
}

.powerBiEmbed {
  height: 94vh;
}

.unavailableMsgContainer {
  display: flex;
  margin-top: 10em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.unavailableMsg {
  width: 50%;
}
