.wrapper {
  margin-bottom: 1.5rem;
  margin: 10px;
}

.withHelpText {
  margin-bottom: 2px;
}

.tdPopoverMenu {
  width: 35px;
}


.table {}

.thead {
  color: #68778D;
  font-weight: normal;
}

.tbody {
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 1%;
}

.hjelptekst {
  margin-left: 10px;
}