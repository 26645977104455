.deleteModal {
  min-width: 600px;
}

.deleteModalBody {
  text-align: center;
}

.deleteModalBody > div {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
