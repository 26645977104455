.modalContainer {
  min-width: 600px;
  overflow-y: initial !important
}

.modalBody {
  height: 80vh;
  overflow-y: auto;
}

.tabPane {
  padding: 1em 0em;
}

.headerSpan {
  display: block;
}

.valueSpan {
  display: block;
  font-size: 1.1rem;
}