.popover > div {
  padding: 0
}

.iconCol {
  background-color: #fcd7ad;
  display: flex;
  align-items: center;
  padding-left: 17px;
}

.warningCol {
  padding-top: 10px;
}

.warningCol > p {
  white-space: pre-wrap;
}