@import '@tradesolution/iceberg-styles/src/scss/variables';

.mainHeader {
    text-align: left;
    font-size: 20px;
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 2em;
}

.periodBox {
    background-color: $Sideground-PU200;
    border-radius: 8px;
    border: 1px solid $GY200;
    padding: 10px;
    margin-right: 1rem;
    font-weight: 600;
    color: $Muted-GY400;
}

.rightAlign {
    text-align: end;
    width: 160px;
}

.middleAlign {
    text-align: center;
    width: 160px;
}

.invoiceContainer {
    background-color: $Foreground-B000;
    padding: 1rem;
    border-radius: 10px;
}

.fitContent {
    width: 1%;
    white-space: nowrap;
}

.tableBody {
    background-color: #fff;
    width: 100%;
    margin-bottom: 1%;
}

.invoiceLinesTable {
    th {
        color: #68778D;
        font-weight: 600;
    }

    thead {
        tr {
            border: 0 !important;
            background-color: #f7fafc !important;
        }
    }
}